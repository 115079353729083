import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Draggable from './Draggable';
import kStore from './store';
import './style.scss';

@observer
export default class DndContext extends React.Component {

	static propTypes = {
		className: PropTypes.string,
	};
	
	componentDidMount() {
		document.addEventListener('mousedown', kStore.onMouseDown);
		document.addEventListener('mouseup', kStore.onMouseUp);
		document.addEventListener('mousemove', kStore.onMouseMove);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', kStore.onMouseDown);
		document.removeEventListener('mouseup', kStore.onMouseUp);
		document.removeEventListener('mousemove', kStore.onMouseMove);
	}
	
	render() {
		const { className, children } = this.props;
		
		const props = {
			id: 'dnd-context',
			className,
			children,
		};
		
		return <div {...props}>
			{children}
			
			{kStore.dragging && <Draggable
				item={kStore.item}
				renderItem={kStore.renderItem}
				itemClassName={kStore.itemClassName}
			/>}
		
		</div>;
	}
	
}
