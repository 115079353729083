import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import kStore from './store';

@observer
export default class Item extends React.Component {

	@observable name = '';
	@observable error = null;
	
	onNameChange = e => this.name = e.target.value;
	
	submit = async () => {
		this.error = null;
		const trimmed = this.name.trim();
		if (trimmed.length > 0) {
			this.props.onSubmit && this.props.onSubmit(trimmed);
		}
		else {
			this.error = 'Введите текст!'
		}
	};
	
	cancel = () => this.props.onCancel && this.props.onCancel();
	
	render() {
		const { list } = this.props;
		
		return <div className="kb-new-item" style={{ borderLeftColor: list.color || kStore.defaultListColor }}>
			<textarea value={this.name} onChange={this.onNameChange} rows={3} autoFocus />
			<div className="actions">
				<Button className="btn-primary" onClick={this.submit}>Добавить</Button>
				<Button onClick={this.cancel}>Отмена</Button>
			</div>
			{this.error && <div className="error">{this.error}</div>}
		</div>;
	}
	
}
