import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import kStore from './store';

@observer
export default class Item extends React.Component {

	onMount = el => {
		kStore.draggable = el;
		if (el) {
			const draggableX = kStore.startDragPos[0];
			const draggableY = kStore.startDragPos[1];
			
			el.style.left = draggableX + 'px';
			el.style.top = draggableY + 'px';
			// console.log('>', el.style.left, 'y', el.style.top);
		}
	};
	
	render() {
		const { item, renderItem, itemClassName } = this.props;
		
		const _className = classNames('kb-item-content', itemClassName);
		
		return <div className="kb-draggable" draggable={false} ref={this.onMount}>
			<div className={_className} style={{ borderLeftColor: kStore.targetList ? (kStore.targetList.color || kStore.defaultListColor) : kStore.defaultListColor }}>
				{renderItem(item.data, kStore.targetList)}
			</div>
		</div>;
	}
	
}
