import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import Item from './Item';
import kStore from './store';

const TRANSITION_DURATION = 100;

@observer
export default class List extends React.Component {
	
	@observable loadingMore = false;
	
	items = {};
	positions = {};
	isUpdated = false;
	
	getSnapshotBeforeUpdate(prevProps, prevState) {
		this.isUpdated = true;
		if (kStore.useAnimation) {
			Object.keys(this.items).forEach(id => {
				const el = this.items[id];
				el.__rect = el.getBoundingClientRect();
				// el.__top = el.offsetTop - this.listEl.scrollTop;
			});
		}
		// console.log('updating...', this.props.list.id, Object.values(this.items).map(el => el.__rect.top));
		return null;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (kStore.useAnimation && this.isUpdated) {
			requestAnimationFrame(() => {
				Object.keys(this.items).forEach(id => {
					const el = this.items[id];
					const isCurrent = kStore.item && kStore.item.id === parseInt(id);
					if (isCurrent) {
						el.style.transition = `none`;
						el.style.transform = `scale(0.8, 0.8)`;
					} else {
						const newRect = el.getBoundingClientRect();
						const delta = newRect.top - el.__rect.top;
						// const delta = el.offsetTop - el.__top - this.listEl.scrollTop;
						// console.log('delta', delta);
						el.style.transition = `none`;
						el.style.transform = `translate(0, ${-delta}px)`;
					}
				});
				requestAnimationFrame(() => {
					Object.keys(this.items).forEach(id => {
						const el = this.items[id];
						el.style.transition = `all ${TRANSITION_DURATION}ms`;
						el.style.transform = `none`;
					});
				});
			});
		}
		// console.log('...updated', this.props.list.id, Object.values(this.items).map(el => el.__rect.top));
		this.isUpdated = false;
	}
	
	onItemMount = (el, id) => {
		if (!kStore.useAnimation) return;
		// console.log('onItemMount', el, id);
		if (el) {
			el.__rect = el.getBoundingClientRect();
			this.items[id] = el;
		} else {
			delete this.items[id];
		}
	};
	
	onMount = el => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
			this.listEl = el;
		}
	};
	
	onScroll = async e => {
		if (this.loadingMore || this.props.list.allLoaded) return;
		const bottomScroll = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
		if (this.props.loadMore && bottomScroll < 50) {
			console.log('loadMore', bottomScroll);
			this.loadingMore = true;
			const items = await this.props.loadMore(this.props.list);
			kStore.addItemsToList(items, this.props.list);
			this.loadingMore = false;
		}
	};
	
	render() {
		const { list, renderItem, itemClassName } = this.props;
		
		// console.log('list items render', list.id, list.name);
		
		return <div className="kb-items" ref={this.onMount}>
			{list.items.map((item, i) => (
				<Item
					key={item.id}
					item={item}
					list={list}
					renderItem={renderItem}
					className={itemClassName}
					getRef={this.onItemMount}
				/>
			))}
			{this.loadingMore && <div className="kb-loading-more"><Loader size={24} color="#888" /></div>}
		</div>;
	}
	
}
