import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import kStore from './store';

const cancel = e => e.preventDefault();

const Item = props => {
	const { item, list, renderItem, className, getRef } = props;

	const ref = el => getRef && getRef(el, item.id);
	
	const dragged = item.isDragged && item.isDragged.get();
	const loading = item.isLoading && item.isLoading.get();
		
	const itemClassName = classNames('kb-item', { dragged, loading });
	
	const _className = classNames('kb-item-content', className);
	let color = list.color || kStore.defaultListColor;
	if (dragged) color = kStore.defaultListColor;
		
	return <Link to={item.path} data-id={item.id} className={itemClassName} onClick={cancel} onDragStart={cancel} ref={ref} draggable={false}>
		<div className={_className} style={{ borderLeftColor: color }}>
			{renderItem(item.data, list)}
		</div>
	</Link>;
}

export default observer(Item);
